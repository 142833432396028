// preact
import { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { route } from 'preact-router'

// stores
import SelfStore from 'stores/self'
import UserStore from 'stores/user'

// components
import Page from 'components/Page'
import Button from 'components/Button'
import Input from 'components/Input'
import Select from 'components/Select'
import Message from 'components/Message'

// consts
import { CITIES, LINKS, LOCAL, MEDIUMS, PLACES, RESOURCES } from 'stores/api/consts'

const Onboarding: FunctionComponent<{ view?: 'creative' | 'partner' }> = ({ view: _view = 'creative' }) => {
	const selfStore = SelfStore.use()
	const userStore = UserStore.use()

	useEffect(() => {
		if (selfStore.user && selfStore.user.slugs?.[0]) {
			route(`/profile/${selfStore.user.slugs[0]}`)
		}
	}, [ selfStore.user ])

	const [ open, setOpen ] = useState<string>('')

	const [ type, setType ] = useState<'creative' | 'partner'>(_view)
	const [ name, setName ] = useState<string>('')
	const [ title, setTitle ] = useState<string | undefined>(undefined)
	const [ email, setEmail ] = useState<string>('')
	const [ city, setCity ] = useState<string>('')
	const [ description, setDescription ] = useState<string>('')
	const [ categories, setCategories ] = useState<string[]>([])

	const [ website, setWebsite ] = useState<string>('')
	const [ instagram, setInstagram ] = useState<string>('')
	const [ youtube, setYoutube ] = useState<string>('')
	const [ facebook, setFacebook ] = useState<string>('')

	const socials = [ {
		id: LINKS.website.id,
		placeholder: 'https://example.com/',
		label: 'Personal Website',
		onInput: setWebsite
	}, {
		id: LINKS.instagram.id,
		placeholder: 'https://www.instagram.com/rccdemeryville/',
		onInput: setInstagram
	}, {
		id: LINKS.youtube.id,
		placeholder: 'https://www.youtube.com/@abgartgroup9715',
		label: 'YouTube',
		onInput: setYoutube
	}, {
		id: LINKS.facebook.id,
		placeholder: 'https://www.facebook.com/profile.php?id=100087325997219',
		onInput: setFacebook
	} ]

	useEffect(() => {
		if (!userStore.loading && userStore.success) {
			route(`/onboarded/?m=Please check your email to complete your profile.&t=success`)
		}
	}, [ userStore.loading, userStore.success ])

	// TODO: Validate social platform URL
	const onSubmit = async (e: Event) => {
		e.preventDefault()
		const links = []
		if (website) {
			links.push({ id: 'website', url: website })
		}
		if (instagram) {
			links.push({ id: 'instagram', url: instagram })
		}
		if (youtube) {
			links.push({ id: 'youtube', url: youtube })
		}
		if (facebook) {
			links.push({ id: 'facebook', url: facebook })
		}
		await userStore.register({ type, email, name, title: title ?? name, description, city, categories, links })
	}

	return <Page name="onboarding">
		<header class="onboarding-header">
			<div class="onboarding-headline">
				<h2 className="onboarding-title">Apply for a profile</h2>
				<div class="onboarding-type">
					<span>as a</span>
					<Select name="type" target="signup-form" label={type}
							select={[ type ]} onSelect={ids => setType(ids[0] as 'creative' | 'partner')}
							open={open} onOpen={setOpen}>
						<Select.Option id="creative" value={'Creative'}>Creative</Select.Option>
						<Select.Option id="partner" value={'Partner'}>Partner</Select.Option>
					</Select>
				</div>
			</div>
			<p className="onboarding-copy">Please fill in the following form to send in your application. You will receive an email at the address
				provided to complete your profile with portfolio images and additional details for RCCD staff to review your eligibility.</p>
		</header>
		{/*TODO: Change me back!*/}
		<form id="signup-form" name="signup" onSubmit={e => (e.preventDefault(), false)}>
			<fieldset id="basic">
				<legend>Basic Info</legend>
				<Select type="radio" name="location" label="Choose city" title="Location"
						description={`While all potential ${type}s are welcome to apply, priority acceptance will be given to those located in Emeryville and the surrounding Bay Area.`}
						select={[ city ]} onSelect={ids => setCity(ids[0])}
						open={open} onOpen={setOpen}>
					{[ ...LOCAL, ...CITIES, ...PLACES, 'Other' ].map((city) =>
							<Select.Option id={city} value={city}>{city}</Select.Option>
					)}
				</Select>
				<Select name="categories" type="checkbox" label="Choose up to 3" title="Categories"
						select={categories} onSelect={setCategories}
						open={open} onOpen={setOpen}>
					{Object.values(type === 'creative' ? MEDIUMS : RESOURCES).map(({ id, title }) =>
							<Select.Option id={id} value={title}>{title}</Select.Option>
					)}
				</Select>
				<Input type="text" name="name" label="Your name" placeholder="Full name" autocomplete="name" required={true}
					   value={name} onInput={setName}/>
				<Input type="text" name="display" label="Display name" placeholder="Name shown on profile" autocomplete="nickname" required={true}
					   value={title ?? name} onInput={setTitle}/>
				<Input type="email" name="email" label="Email address" placeholder="For login & contact" autocomplete="email" required={true}
					   value={email} onInput={setEmail}/>
			</fieldset>
			<fieldset id="profile">
				<legend>Profile Info</legend>
				<Input type="paragraph" name="description" label="Brief Bio" required={true} value={description} onInput={setDescription}
					   placeholder={'Showcase some of your achievements, experience, or anything else you\'d like to share for your application consideration (you can change this later)'}/>
				<fieldset id="socials">
					<legend>Online Presence</legend>
					<div class="social-items">
						{socials.map(({ id, label, placeholder, onInput }) => (
								<Input type="url" name={id} label={label || id.capitalize()} placeholder={placeholder} onInput={onInput}/>
						))}
					</div>
				</fieldset>
			</fieldset>
			<Message type="error">{userStore.errors?.errors?.error}</Message>
			<footer className="signup-footer">
				<Button importance="tertiary" to="/" disabled={userStore.loading}>Cancel</Button>
				<Button importance="primary" type="submit" quality="good" disabled={userStore.loading} onClick={onSubmit}>Submit Application</Button>
				<p className="signup-disclaimer">By applying, you agree to our <a href="">Privacy Policy</a> and <a href="">Terms of Service</a>.
					We will use your name and email address solely for account creation, communication, and improving our services.
					Your information will not be shared with third parties without your consent.
				</p>
			</footer>
		</form>
	</Page>
}

export default Onboarding
