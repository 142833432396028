// preact
import { FunctionComponent } from 'preact'
import { useEffect } from 'preact/hooks'
import { route } from 'preact-router'

// stores
import SelfStore from 'stores/self'

// components
import Page from 'components/Page'


const Onboarded: FunctionComponent = () => {
	const selfStore = SelfStore.use()

	useEffect(() => {
		if (selfStore.user && selfStore.user.slugs?.[0]) {
			route(`/profile/${selfStore.user.slugs[0]}`)
		}
	}, [ selfStore.user ])

	return <Page name="onboarding">
		<header className="onboarding-header">
			<div className="onboarding-headline">
				<h2 className="onboarding-title">Application Sent</h2>
				<span>Please check your email to complete your profile</span>
			</div>
			<p className="onboarding-copy">Your email address needs to be verified. Please check your email for the login credentials to verify your account
				and complete your profile with portfolio images and additional details for RCCD staff to review your eligibility.</p>
		</header>
	</Page>
}

export default Onboarded
