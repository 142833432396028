// preact
import { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Router } from 'preact-router'

// styles
import './app.scss'

// stores
import SelfStore from 'stores/self'

//components
import Button from 'components/Button'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Message from 'components/Message'
import Auth from 'components/Auth'

// pages
import List from 'pages/List'
import Detail from 'pages/Detail'
import Onboarding from 'pages/Onboarding'
import Onboarded from 'pages/Onboarded'

const NavButton: FunctionComponent<{
	from?: string, to?: string, onClick?: () => void
}> = ({
	from, to, onClick, children
}) => {
	return <Button aria-current={from === to ? 'page' : undefined} to={to} onClick={onClick}>
		{children}
	</Button>
}

const App = () => {
	const selfStore = SelfStore.use()

	useEffect(() => {
		selfStore.self()
	}, [])

	const [ url, setUrl ] = useState('/')
	const [ auth, setAuth ] = useState<{
		show: boolean, view: 'creative' | 'partner', action: 'login' | 'signup'
	}>({ show: false, view: 'creative', action: 'login' })
	const [ show, setShow ] = useState(false)

	const onAuthClick = ({ show, view, action }: { show?: boolean, view?: 'creative' | 'partner', action?: 'login' | 'signup' }) => {
		if (show) {
			setShow(false)
		}
		setAuth(({
			show: _show, view: _view, action: _action
		}) => ({
			show: show ?? _show, view: view ?? _view, action: action ?? _action
		}))
	}

	const onSignOut = () => {
		if (selfStore.user) {
			if (document.scrollingElement) {
				document.scrollingElement.scrollTop = 0
			}
			selfStore.logOut()
		}
	}

	const onDismiss = () => {
		window.history.replaceState(null, '', window.location.pathname)
	}

	const query = new URLSearchParams(window.location.search)
	const m = query.get('m')
	const t = [ 'success', 'error', 'info' ].includes(query.get('t') ?? '') ? query.get('t') as 'success' | 'error' | 'info' : undefined
	const login = query.get('login') === ''

	useEffect(() => {
		if (!!m && document.scrollingElement) {
			document.scrollingElement.scrollTop = 0
		}
	}, [ m ])

	useEffect(() => {
		if (login) {
			setAuth(({ view }) => ({ show: true, view, action: 'login' }))
			window.history.replaceState(null, '', window.location.pathname)
		}
	}, [ login ])

	return selfStore.selfing ? <p>Loading...</p> : <>
		<a href="#content" className="nav-skip">Skip to main content</a>
		<Header show={show} onShow={setShow}>
			<NavButton from={url} to="/">Database</NavButton>
			<NavButton from={url} to={selfStore.user?.slugs?.length ? `/profile/${selfStore.user?.slugs?.[0]}` : undefined}
					   onClick={!selfStore.user ? () => onAuthClick({ show: true, action: 'login' }) : undefined}
			>My Profile</NavButton>
			<NavButton from={url} to={'https://www.rottencityculturaldistrict.com/'}>About</NavButton>
		</Header>
		<Router onChange={({ url }) => setUrl(url)}>
			{/*<Landing path={'/landing'}/>*/}
			<List path="/" default
				  onShowAuth={(view, action) => onAuthClick({ show: true, view, action })}/>
			<Detail path="/profile/:slug"/>
			<Onboarding path="/onboarding"/>
			<Onboarded path="/onboarded"/>
		</Router>
		<Message type={t} dismissable={true} onDismiss={onDismiss}>{m}</Message>
		<Footer>
			<Button to={'https://www.rottencityculturaldistrict.com/'}>Main RCCD site</Button>
			<Button to={'https://www.instagram.com/rccdemeryville/'}>Instagram</Button>
			<Button importance="primary" onClick={selfStore.user ? onSignOut : () => {
				if (document.scrollingElement) {
					document.scrollingElement.scrollTop = 0
				}
				onAuthClick({ show: true, action: 'signup' })
			}}
			>{selfStore.user ? 'Sign out' : 'Create a profile'}</Button>
		</Footer>
		<Auth view={auth.view} action={auth.action} isOpen={auth.show}
			  onClose={() => onAuthClick({ show: false })}
		/>
	</>
}

export default App
